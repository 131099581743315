import React from 'react'

import { Container } from 'components/System'
import Logo from 'components/Logo'
import TrendingTopics from 'images/trendingTopics.svg'
import Wko from 'images/wko.png'

const Component = () => (
  <div className="w-full py-4 sm:py-8 flex-shrink-0 bg-white">
    <Container>
      <div className="flex items-center">
        <div className="flex-grow">
          <Logo />
        </div>
        <div className="flex-shrink flex flex-col items-end space-y-1 md:space-y-2">
          <p className="text-xs text-gray-800">Bekannt aus</p>
          <a
            className="block"
            href="https://www.trendingtopics.at/digitale-corona-gaestelisten-fuer-lokale-sind-der-neue-renner/"
            target="_blank"
            rel="external noreferrer"
            style={{ maxWidth: 124 }}
          >
            <img src={TrendingTopics} alt="Trending Topics" />
          </a>
          <a
            className="block"
            href="https://www.wko.at/branchen/tourismus-freizeitwirtschaft/gastronomie/kontaktpersonencovid.html"
            target="_blank"
            rel="external noreferrer"
            style={{ maxWidth: 60 }}
          >
            <img src={Wko} alt="Wirtschaftskammer Österreich (WKO)" />
          </a>
        </div>
      </div>
    </Container>
  </div>
)

export default Component
