import React from 'react'
import { Link } from 'gatsby'

import { Container } from 'components/System'

const legal = [
  { label: 'Datenschutz', route: '/datenschutz' },
  { label: 'Impressum', route: '/impressum' },
]

const Component = () => {
  return (
    <div className="py-8 md:py-16 lg:py-24 flex-shrink-0">
      <Container>
        <div className="grid md:grid-cols-12 gap-8">
          <div className="col-span-6 lg:order-last">
            <ul className="flex -m-4 items-center">
              {legal.map(({ label, route }, index) => (
                <React.Fragment key={index}>
                  <li className="px-4 py-2">
                    <Link
                      to={route}
                      className="block py-2 md:text-sm hover:underline"
                    >
                      {label}
                    </Link>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
          <div className="col-span-6 lg:order-first">
            <p className="text-sm">
              © 2020 Besucherliste.at. Alle Rechte vorbehalten.
            </p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Component
