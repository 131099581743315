import React from 'react'
import { Link } from 'gatsby'

export const Container = (props) => <div className="container" {...props} />

export const Button = ({
  to,
  external,
  fullWidth,
  className,
  onClick,
  type,
  ...props
}) => {
  const TagName = to ? Link : onClick || type === 'submit' ? 'button' : 'a'

  return (
    <TagName
      to={to}
      className={`inline-block px-6 py-5 rounded bg-purple-700 font-medium leading-none text-white text-center ${
        fullWidth ? 'w-full' : ''
      } ${className}`}
      onClick={onClick}
      type={type}
      {...props}
    />
  )
}
