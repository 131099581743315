import React from 'react'
import { Link } from 'gatsby'
import CookieConsent from 'react-cookie-consent'

import { TRACKING_COOKIE } from 'utils'

const Consent = () => {
  return (
    <div className="fixed inset-0 top-auto z-50" style={{ bottom: '1rem' }}>
      <div className="container">
        <CookieConsent
          buttonText="Einverstanden"
          declineButtonText="Ablehnen"
          enableDeclineButton
          cookieName={TRACKING_COOKIE}
          cookieValue={true}
          declineCookieValue={false}
          containerClasses="max-w-screen-lg md:flex md:items-center p-4 mx-auto space-y-4 md:space-y-0 bg-purple-700 rounded text-white text-sm"
          contentClasses="flex-grow pr-8"
          buttonWrapperClasses="flex flex-shrink-0 items-center justify-end space-x-4"
          buttonClasses="px-3 py-2 rounded bg-white font-medium leading-none text-purple-700"
          declineButtonClasses="px-3 py-2 rounded leading-none"
          disableStyles={true}
          expires={150}
          // eslint-disable-next-line no-restricted-globals
          onAccept={() => location.reload()}
          // eslint-disable-next-line no-restricted-globals
          onDecline={() => location.reload()}
        >
          Diese Website verwendet Cookies, um bestimmte Funktionen und das
          Angebot zu verbessern. Möchten Sie der Nutzung von Cookies und
          Analytics gemäß unserer{' '}
          <Link to="/datenschutz" className="underline">
            Datenschutzerklärung
          </Link>{' '}
          zustimmen?
        </CookieConsent>
      </div>
    </div>
  )
}

export default Consent
