import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({
  lang = 'de',
  title = null,
  description,
  meta = [],
  type,
  image,
  pathname = '',
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            defaultTitle: title
            defaultDescription: description
            defaultImage: image
          }
        }
      }
    `
  )

  const {
    siteMetadata: { siteUrl, defaultImage, defaultTitle, defaultDescription },
  } = site

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={`%s — ${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: description || defaultDescription,
        },
        {
          name: `og:url`,
          content: `${siteUrl}${pathname}`,
        },
        {
          property: `og:title`,
          content: title || defaultTitle,
        },
        {
          property: `og:description`,
          content: description || defaultDescription,
        },
        {
          property: `og:type`,
          content: type || `website`,
        },
        {
          property: `og:image`,
          content: `${siteUrl}${image || defaultImage}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title || defaultTitle,
        },
        {
          name: `twitter:description`,
          content: description || defaultDescription,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
